import { useState } from 'react';
import { CBadge, CDropdown, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro';
import { useTranslation } from 'react-i18next';
import Icon from '../mui-icon/Icon';

const Notifications = () => {
    const [unseenCount] = useState<number | null>(null);

    const [notifications] = useState<string[]>([]);
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

    const { t } = useTranslation();
    // Display the last 10 notifications
    const lastTenNotifications = notifications?.slice(0, 10);

    const handleDropdownToggle = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    return (
        <CDropdown autoClose="outside" variant="nav-item">
            <div onClick={handleDropdownToggle}>
                <CDropdownToggle caret={false}>
                    <Icon name="Notifications" />
                    <CBadge shape="rounded-pill" color="danger">
                        {unseenCount}
                    </CBadge>
                </CDropdownToggle>
            </div>

            <CDropdownMenu className="pt-0">
                <CDropdownHeader className="bg-light fw-semibold py-2">{t('Notifications')}</CDropdownHeader>
                <div style={{ width: '320px', minHeight: '400px' }}>
                    {lastTenNotifications?.map((notification: any, ind: number) => (
                        <CDropdownItem disabled key={JSON.stringify(notification)}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-column">
                                    <div className="fw-semibold">
                                        {ind}. {notification}
                                    </div>
                                    <div className="text-medium-emphasis small"></div>
                                </div>
                                <div className="text-medium-emphasis small">{notification.time}</div>
                            </div>
                        </CDropdownItem>
                    ))}
                </div>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default Notifications;

