import { useTranslation } from 'react-i18next';
import { getSource } from '../../../utils';
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import { newlineStyle } from '../../../views/pages/union/ClientViewt';
import { FieldComponentProps } from '../Field';
import { useState } from 'react';

const RepairCostField = (props: FieldComponentProps) => {
    const { t } = useTranslation();
    const [source, setSource] = useState({});
    React.useEffect(() => {
      getSource(props.field.source).then(value => setSource(value));
    }, []);

    return (
        <div>
            <CFormLabel htmlFor="repairCost" className="mb-0">
                {t(props.field.label ?? 'עלות תיקון')}
            </CFormLabel>
            <div style={{ ...newlineStyle }}></div>
            <CFormInput
                style={{ textAlign: 'center', padding: '14px 10px', borderRadius: '8px', backgroundColor: 'white', border: '2px ridge black' }}
                type="number"
                id="repairCost"
                name="עלות תיקון"
                placeholder={'עלות תיקון: ' + source + " ש''ח"}
            />
        </div>
    );
};

export default RepairCostField;
