import { PageInterface } from '../../types';

export const getRoute = (page: PageInterface) => {
    const route = page._path?._id ?? page._url;

    if (route?.startsWith('/')) {
        return route;
    } else {
        return `/${route}`;
    }
};
