import webSocket from '../services/websocket/webSocket';
import { Source } from '../types';

export function getSource(source?: Source): Promise<never> {
   const { sendMessage } = webSocket();
  // const runtime = useAppSelect('runtime');
  return new Promise((resolve, reject) => {
    if (!source) {
      resolve(undefined);
    } else if (typeof source === 'string') {
      // Source has been given inline and should not be processed
      resolve(source);
    } else if (source.type === 'literal') {
      resolve(source.value);
    } else if (source.type === 'field') {
      // future.resolve(_.get(runtime, source.value) ?? source.defaultValue ?? null);
    } else if (source.type === 'url') {
      sendMessage(source.value, 'GET')?.then(response => {
        resolve(response.data ?? response);
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
    }
  });
}
