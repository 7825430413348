import { Buffer } from 'buffer';
import { Emitter } from '../../services/emitter/Emitter';
import webSocket from '../../services/websocket/webSocket';
import { staticRoutes } from '../../configuration/constants';

export function getFile(id): Promise<File | null> {
  const { sendMessage } = webSocket();
  return new Promise((resolve, reject) => {
    if (!id) {
      reject(new Error('id is required'));
    }
    const chunks: Buffer[] = [];
    const onMessage = (message: { part: string | number; data: WithImplicitCoercion<string> | { [Symbol.toPrimitive](hint: 'string'): string; }; }) => {
      chunks[message.part] = Buffer.from(message.data, 'base64');
    };
    Emitter.getInstance().on(`${staticRoutes.files}.${id}`, onMessage);
    sendMessage(staticRoutes.files, 'GET', {filters: {_id: id}})
    .then(done => {
      try {
        Emitter.getInstance().removeListener(`${staticRoutes.files}.${id}`, onMessage);
        resolve(new File([new Blob(chunks)], done.name));
      } catch (e) {
        console.error(e);
        reject(e);
      }
    })
    .catch(error => {
      console.error(error);
      reject(error);
    });
  });
}
