import { CButton } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { initializeForm, updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import { FieldInterface } from '../input/types';
import { Source, WidgetInterface } from '../../types';
import { loadApi } from '../../utils/google-map';
import useResponsiveStyles from '../../utils/useResponsiveStyles';
import StyleWrapper from '../style-wrapper';
import Field from './Field';
import useAppSelect from '../../services/redux/useAppSelect';

// This union sets 'modal' and 'open' as required if either is present, because modal forms must have an open prop
export type FormProps = {
    id?: string;
    row?: Record<string, unknown>;
    content: FieldInterface[];
    apiPath?: string;
    onSubmit?: (data: Record<string, unknown>) => void;
    source?: Source;
    subtype?: 'multi';
    widget: WidgetInterface;
};

const Form = (props: FormProps) => {
    const { widget } = props;

    useAppSelect('runtime');
    const dispatch = useAppDispatch();
    const content = widget?._content ?? [];
    const id = widget?._id ?? 'form';

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (content.length) {
            content.forEach(field => {
                if (field.type === 'map' || field.type === 'location') {
                    setLoading(true);
                    loadApi().then(() => setLoading(false));
                } else {
                    setLoading(false);
                }

                dispatch(initializeForm(id, content));
            });
        }
    }, [content.length, t, i18n.language]);

    const gridResponsiveColumns = useResponsiveStyles({
        gridTemplateColumns: {
            mobile: 'auto min-content ',
            tablet: '1fr 1fr',
            laptop: '1fr 1fr 1fr',
        },
    });

    const randomDigits = (length: number) => {
        let str = '';
        for (let i = 0; i < length; i++) {
            str += Math.floor(Math.random() * 10);
        }

        return str;
    };

    const randomLetters = (length: number) => {
        let str = '';
        const letters = 'abcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < length; i++) {
            str += letters.charAt(Math.floor(Math.random() * letters.length));
        }

        return str;
    };

    const setDevData = () => {
        const data = {
            _id: randomDigits(9),
            firstName: 'John',
            lastName: 'Doe',
            eNumber: randomDigits(6),
            email: 'gmail@gmail.com',
            telephone: randomDigits(10),
            deploy_type: '0',
            username: 'johndoe',
            password: 'Secure!Password123',
            name: 'main',
            userId: randomDigits(9),
            license: randomLetters(20),
            address: randomLetters(10),
            website: randomLetters(6),
        };

        content.forEach(field => {
            if (data[field._key]) {
                dispatch(updateRuntimeData(`${id}.${field._key}.value`, data[field._key], 'merge'));
                dispatch(updateRuntimeData(`${id}.${field._key}.validated`, 'true', 'merge'));
            }
        });
    };

    if (loading) return null;
    return (
        <div id={id}>
            <StyleWrapper {...gridResponsiveColumns} display="grid" gap="1rem" border="none" outline="none" boxShadow="none">
                {content.map((field: FieldInterface) => (
                    <Field field={field} widgetId={`${id}`} key={field._key}/>
                ))}
            </StyleWrapper>
            {window.location.hostname === 'localhost' && <CButton onClick={setDevData}>Set Data</CButton>}
        </div>
    );
};

export default Form;
