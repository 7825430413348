import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCol, CForm, CButton, CCardFooter, CRow, CCardHeader, CFormCheck, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CBadge } from '@coreui/react-pro';
import Field from '../../../components/form/Field';
import { useTranslation } from 'react-i18next';
import webSocket from '../../../services/websocket/webSocket';
// import { useSendMessage, useWebSocket } from '../../../services/websocket/useWebSocket';
import { Box, CircularProgress, Stack } from '@mui/material';
import { FieldInterface } from '../../../components/input/types';
import { Operation, PageInterface, WidgetInterface } from '../../../types';
import useAppDispatch from '../../../services/redux/useAppDispatch';
import { setAppRoutes } from '../../../services/redux/globalStateManager';
import useAppSelect from '../../../services/redux/useAppSelect';
import { MessageBody } from '../../../services/websocket/WebSocketSingleton';

interface BTError {
    code: 400 | 401 | 403 | 404 | 500;
    description: string;
}

interface SendMessageState {
  loading: boolean;
  response: MessageBody | null;
  error: BTError | null;
}

export const CreateRoleComponent: React.FC = () => {
    const { t } = useTranslation();
    const { sendMessage } = webSocket();
    const intermediateSendMessage = () => {
      const [state, setState] = useState<SendMessageState>({ loading: false, response: null, error: null });

      const request = async (url: string, operation: Operation, body?: MessageBody, id = '') => {
        if (!id) id = url;

        setState({ loading: true, response: null, error: null });
        sendMessage(url, operation, body, id)
            .then(async response => {
                const resp = response ? (response as MessageBody) : null;
                setState({ loading: false, response: resp, error: null });
                return response;
            })
            .catch(async error => {
                setState({ loading: false, response: null, error: error });
                return error;
            });
      };
      return { request, ...state };
    };
    const { response: pages, sendMessage: wsGetPages } = intermediateSendMessage;
    const { response: appRoutes, sendMessage: getAppRoutes } = intermediateSendMessage;
    const [pagesWidgets, setPagesWidgets] = useState<Partial<Record<string, WidgetInterface[]>>[]>();
    const [pagesStates, setPagesStates] = useState<Record<string, { isOpen: boolean; isLoading: boolean }>>({});
    const dispatch = useAppDispatch();
    const rtl = useAppSelect('rtl');

    useEffect(() => {
        if (!appRoutes) {
            getAppRoutes('/metadata', 'GET');
        } else {
            dispatch(setAppRoutes(appRoutes));
        }
    }, [appRoutes]);

    const getPages = () => {
        if (appRoutes && appRoutes._pages) {
            wsGetPages(appRoutes._pages, 'GET');
        }
    };

    useEffect(() => {
        getPages();
    }, []);
    useEffect(() => {
        if (appRoutes) {
            getPages();
        }
    }, [appRoutes]);
    const Checkbox = (label: string, itemId: string) => (
        <Box
            component="label"
            sx={{
                display: 'inline-block',
                padding: '8px',
                marginRight: '8px',
                marginLeft: '8px',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                borderRadius: '8px',
            }}
            onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = 'var(--cui-input-bg)';
            }}
            onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = 'transparent';
            }}
            onClick={e => e.stopPropagation()}
        >
            <CFormCheck inline type="checkbox" id={`${label}-${itemId}`} label={t(label)} onClick={e => e.stopPropagation()} />
        </Box>
    );

    const renderCrudCheckboxes = (itemId: string, mode: 'field' | 'page' | 'widget') => (
        <div className="crud-checkboxes" style={{ position: 'absolute', right: rtl ? 'unset' : '2rem', left: rtl ? '2rem' : 'unset', top: '20%' }}>
            {Checkbox('Read', itemId)}
            {Checkbox('Create', itemId)}
            {Checkbox('Update', itemId)}
            {mode !== 'field' && Checkbox('Delete', itemId)}
        </div>
    );

    const renderFields = (fields: FieldInterface[]) => {
        return (
            <CAccordion style={{ position: 'relative' }}>
                {fields.map(field => (
                    <CAccordionItem key={field._key}>
                        <CAccordionHeader>
                            <Stack direction="row" spacing={4} className="accordion-header-content">
                                <>
                                    <strong style={{ alignSelf: 'center' }}>{t(field.label)}</strong>{' '}
                                    <CBadge style={{ alignSelf: 'center', marginRight: '0.5rem', marginLeft: '0.5rem' }} color="info">
                                        {field.type}
                                    </CBadge>
                                </>
                                {renderCrudCheckboxes(field._key, 'field')}
                            </Stack>
                        </CAccordionHeader>
                        <CAccordionBody>{field._content && Array.isArray(field._content) && renderWidgets(field._content)}</CAccordionBody>
                    </CAccordionItem>
                ))}
            </CAccordion>
        );
    };

    const renderWidgets = (widgets: WidgetInterface[]) => (
        <CAccordion>
            {widgets.length === 0 && <>{t('No widgets found') + '.'}</>}
            {widgets.map(widget => (
                <CAccordionItem key={widget._id}>
                    <CAccordionHeader>
                        <Stack direction="row" spacing={4} className="accordion-header-content" alignContent="center">
                            <>
                                <strong style={{ alignSelf: 'center' }}>{t(widget.label)}</strong>{' '}
                                <CBadge style={{ alignSelf: 'center', marginLeft: '0.5rem', marginRight: '0.5rem' }} color="primary">
                                    {widget._type}
                                </CBadge>
                            </>
                            {renderCrudCheckboxes(widget._id, 'widget')}
                        </Stack>
                    </CAccordionHeader>
                    <CAccordionBody>{widget._content && Array.isArray(widget._content) && renderFields(widget._content)}</CAccordionBody>
                </CAccordionItem>
            ))}
        </CAccordion>
    );

    const fetchPageContent = (page: PageInterface) => {
        const isOpen = !pagesStates[page._id]?.isOpen;
        if (!isOpen) {
            return;
        }
        setPagesStates({ ...pagesStates, [page._id]: { isOpen, isLoading: true } });

        const minimumDelay = new Promise(resolve => setTimeout(resolve, 500));
        sendMessage(page._path + '/widgets', 'GET')
            .then((response: WidgetInterface[]) => Promise.all([response, minimumDelay]))
            .then(([response]) => {
                // Destructure response from the array
                if (response) {
                    setPagesWidgets({ ...pagesWidgets, [page._id]: response });
                }
                setPagesStates({ ...pagesStates, [page._id]: { isOpen, isLoading: false } });
            })
            .catch(() => {
                setPagesStates({ ...pagesStates, [page._id]: { isOpen, isLoading: false } });
            });
    };

    return (
        <CForm style={{ padding: '2rem 0.5rem' }}>
            <h1>{t('Create Role')}</h1>

            <CCard>
                <CCardBody>
                    <Stack direction="row" spacing={4} alignItems="center">
                        <CCol md="6">
                            <Field id="role-name" field={{ properties: [], _key: 'role-name', _label: 'Role Name', _type: 'text' }} />
                        </CCol>
                        <CCol md="6">
                            <CFormCheck defaultChecked inline type="checkbox" id="role-name" label={t('Automatic Translations')} />
                        </CCol>
                    </Stack>
                    <CRow>
                        <CCardHeader>{t('Pages')}</CCardHeader>
                        {pages && Array.isArray(pages) && (
                            <CAccordion>
                                {pages.map((page: PageInterface) => (
                                    <CAccordionItem onClick={() => fetchPageContent(page)} key={page._id}>
                                        <CAccordionHeader>
                                            <div className="accordion-header-content">
                                                <strong>{t(page.name ?? page._id)}</strong>
                                                <Box padding="0 1rem">{renderCrudCheckboxes(page._id)}</Box>
                                            </div>
                                        </CAccordionHeader>
                                        {pagesStates && pagesStates[page._id]?.isOpen && pagesStates[page._id]?.isLoading && <CircularProgress sx={{ marginTop: '0.5rem', marginLeft: '2rem' }} />}
                                        <CAccordionBody>{pagesWidgets && Array.isArray(pagesWidgets[page._id]) && renderWidgets(pagesWidgets[page._id])}</CAccordionBody>
                                    </CAccordionItem>
                                ))}
                            </CAccordion>
                        )}
                    </CRow>
                </CCardBody>
                <CCardFooter>
                    <Stack direction="row" spacing={2}>
                        <CButton type="submit" size="sm" color="primary" className="mr-1">
                            {t('Save')}
                        </CButton>
                        <CButton type="reset" size="sm" color="danger" className="ml-1">
                            {t('Cancel')}
                        </CButton>
                    </Stack>
                </CCardFooter>
            </CCard>
        </CForm>
    );
};

export default CreateRoleComponent;
