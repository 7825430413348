import { CLoadingButton } from '@coreui/react-pro';
import { Box, Link } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSource } from '../../utils';
import Icon from '../mui-icon/Icon';
import { FieldComponentProps } from '../form/Field';

const MapWithUserLocation: React.FC<FieldComponentProps> = props => {
    const [loading, setLoading] = useState<boolean>(false);
    const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral>();
    const [, setError] = useState<string | null>(null);
    const [source, setSource] = useState({});
    getSource(props.field.icon).then(value => setSource(value));
    const { t } = useTranslation();

    const requestLocation = () => {
        setLoading(true);
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                    setLoading(false);
                    setError(null);
                },
                error => {
                    console.error('Error getting user location:', error);
                    setLoading(false);
                    setError(error.message);
                }
            );
        }
    };

    const createGoogleMapsLink = useCallback((lat, lng) => `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, []);

    return (
        <Box>
            <CLoadingButton loading={loading} onClick={requestLocation}>
                {userLocation ? (
                    <Link href={createGoogleMapsLink(userLocation.lat, userLocation.lng)} target="_blank" rel="noopener noreferrer">
                        {t('Open in Google Maps')}
                    </Link>
                ) : (
                    <>
                        {props.field.label && t(props.field.label ?? 'Get Location')}
                        {props.field.icon && source && <Icon name={source} />}
                    </>
                )}
            </CLoadingButton>
        </Box>
    );
};

export default MapWithUserLocation;
