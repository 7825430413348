import { SxProps } from '@mui/material';
import { AppTheme, PrimaryColors } from './services/redux/globalStateManager';
import { FieldInterface, Permission } from './components/input/types';

export type Operation = 'GET' | 'CREATE' | 'UPDATE' | 'DELETE';

export enum Mode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  VIEW = 'VIEW'
}

export type WidgetType = 'submit' | 'location' | 'image' | 'multi-page' | 'TableWidget' | 'map' | 'calendar' | 'gallery' | 'video' | 'audio' | 'diagram' | 'Statistics' | 'form';

export type StatisticsSubtype = 'sum' | 'avg' | 'min' | 'max';
export type FormSubtype = Operation;

export interface Source {
    type: 'literal' | 'field' | 'url';
    value: fieldKey;
    defaultValue?: string;
}

export interface Query {
  filters?: {[key: string]: any}
  sort?: string
  order?: 'asc' | 'desc'
  size: number
  page: number
}

export type WidgetInterface = {
    _type: WidgetType;
    style?: Style;
    subtype?: FormSubtype | StatisticsSubtype | 'multi' | 'ocr-closeup';
    _id: string;
    label?: string;
    permission?: Permission;
    icon?: Source;
    _columnWeight?: number;
    _position?: number;
    _path?: Path;
    _content?: FieldInterface[];
};

export type CustomStyleCondition<T> = {
    condition: {
        select: `field.${keyof FieldInterface}` | `widget.${keyof WidgetInterface}`;
        compare: string;
        iftrue: T;
        else: T;
    };
};

export type DynamicCSSProperties = {
    [Property in keyof SxProps]: SxProps[Property] | CustomStyleCondition<SxProps[Property]>;
};

export type Style = {
    flexGroups: Array<{
        elements: string[];
        flexProperties: React.CSSProperties;
    }>;
    individualStyles: {
        [key: string]: DynamicCSSProperties;
    };
};

export type Path = {
  _id: string;
  _type: string;
}

export type PageInterface = {
    _id: string;
    _path: Path;
    name?: string;
    _widgets?: WidgetInterface[]; // Do we use .widgets or .content?
    icon?: string;
    sideMenu?: boolean; // default is true
    footer?: boolean; // default is true
    _style?: Style;
    order?: number;
};

export type SelectionOption = {
    _id: number;
    label: string;
    key: string;
};

export type User = {
    primaryColor: PrimaryColors;
    appFont: string;
    fontSize: string;
    tFormat: string;
    dFormat: string;
    language: {
        _id: string;
    };
    defaultTheme: AppTheme;
    image: string;
    sidebarTheme: AppTheme;
};

export type Settings = {
    defaultTheme: AppTheme;
    theme: AppTheme;
    primaryColor: PrimaryColors;
    appFont: string;
    fontSize: string;
    language: string;
    sidebarTheme: string;
};

export function permissionCheck(decimal: number | undefined, index: number): boolean {
  if (decimal === undefined) {
    return true;
  }
  // Convert the decimal to octal using the toString(8) method
  const octal = decimal.toString(8);
  // Pad with leading zeros to ensure the length is 4
  return octal.padStart(4, '0')[index] === '1';
}

// |=================================  FORM AND FIELDS =====================================|
/**
 * This array enables us to iterate over all the field types, while still having type safety,
 * while still making sure type FieldType is updated automatically with every new array entry.
 */

export const transforms: readonly string[] = ['ocr']; // Can add more transforms in the future
Object.freeze(transforms); // Prevents modifying transforms during runtime

// Represents a key in memory's global storage. for example: 'car-ocr-form.barcode-img.base64'
export type fieldKey = string;
