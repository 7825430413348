import { CContainer } from '@coreui/react-pro';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/loader/Loader';
import Page from '../../../components/page/Page';
import StyleWrapper from '../../../components/style-wrapper';
import { changeTheme, setRtl } from '../../../services/redux/globalStateManager';
import useAppDispatch from '../../../services/redux/useAppDispatch';
import { PageInterface as ViewType } from '../../../types';
import { buttonStyle } from './ClientViewt';

export const MultiPageContext = React.createContext({
    functions: {
        goToNextPage: () => null,
    },
});

const MultiPageComponent: React.FC = props => {
    const pages = props?.widget?.content;
    const [currentPageInd, setCurrentPageInd] = useState<number>(0);
    const { watch, setValue } = useForm();
    const { t, i18n } = useTranslation();
    const [currPagevalid, setCurrPageValid] = React.useState<boolean>(true);
    const dispatch = useAppDispatch();

    const [activePage, setActivePage] = React.useState<ViewType | null>(null);
    const registerEvent = () =>
        document.dispatchEvent(
            new CustomEvent(activePage?.next?.onClick?.name, {
                detail: activePage?.next?.onClick?.name,
            })
        );

    React.useEffect(() => {
        dispatch(changeTheme('light-theme'));
        dispatch(setRtl(true));
        i18n.changeLanguage('he');

        document.documentElement.dir = 'rtl';

        window.setTimeout(() => {
            document.documentElement.classList = '';
            dispatch(changeTheme('light-theme'));
            dispatch(setRtl(true));
        }, 50);

        return () => document.removeEventListener(activePage?.next?.onClick?.name, registerEvent, false);
    }, []);
    const functions = {
        goToNextPage: () => {
            if (currentPageInd < pages.length - 1) {
                setCurrentPageInd(currentPageInd + 1);
            }
        },
    };

    React.useEffect(() => {
        setActivePage(null);
        setTimeout(() => {
            setActivePage(pages[currentPageInd]);
        }, 100);
    }, [currentPageInd, pages.length]);

    const safelyParseJSON = (jsonString: string | null): any => {
        try {
            return jsonString !== null ? JSON.parse(jsonString) : null;
        } catch (e) {
            console.error('Error parsing JSON from localStorage:', e);
            return null;
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name) {
                localStorage.setItem(name, JSON.stringify(value[name]));
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    const clearLocalStorage = (): void => {
        pages?.forEach(page => {
            page?.fields?.forEach(field => {
                localStorage.removeItem(field._key);
            });
        });
    };

    const onValidChange = React.useCallback(
        isValid => {
            setCurrPageValid(isValid);
        },

        []
    );

    const view = React.useMemo(() => <Page isValid={currPagevalid} onValidChange={onValidChange} page={pages[currentPageInd]} />, [currentPageInd]);

    return (
        <MultiPageContext.Provider value={{ functions }}>
            <CContainer>
                <CContainer style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', minHeight: '100vh' }}>
                    {props.logo && (
                        <StyleWrapper display="flex">
                            <img style={{ marginLeft: 'auto', marginRight: 'auto' }} src={props.logo} alt={props.logo} width={190} height={90} />
                            <br />
                            <br />
                            <br />
                        </StyleWrapper>
                    )}
                    {view}
                    {activePage === null && <Loader type="circular" />}
                    {/* Navigation Controls */}
                    <Stack direction="row" spacing={2} style={{ marginTop: 'auto', paddingBottom: '65px', width: '100%' }}>
                        {pages.length > 1 && (
                            <div style={{ width: '100%', display: 'flex', gap: '0.5rem' }}>
                                {currentPageInd > 0 && (
                                    <button style={{ ...buttonStyle, color: 'orange', width: '100%' }} color="secondary" onClick={() => setCurrentPageInd(currentPageInd - 1)}>
                                        {t('Previous')}
                                    </button>
                                )}
                                {currentPageInd < pages.length && (
                                    <button
                                        style={{ ...buttonStyle, color: 'red', width: '100%' }}
                                        disabled={!currPagevalid}
                                        onClick={() => {
                                            registerEvent();
                                            if (currentPageInd < pages.length - 1) {
                                                setCurrentPageInd(currentPageInd + 1);
                                            }
                                        }}
                                    >
                                        {t(activePage?.next?.title)}
                                    </button>
                                )}
                            </div>
                        )}
                    </Stack>
                </CContainer>
            </CContainer>
        </MultiPageContext.Provider>
    );
};

export default MultiPageComponent;
