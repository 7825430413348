import { CDatePicker } from '@coreui/react-pro';
import { FieldComponentProps } from '../Field';

const Date = (props: FieldComponentProps) => {
    const onDateChangeDefault = (date: Date | null) => {
        console.warn('No change event supplied for Date Picker:', date);
    };

    return <CDatePicker onDateChange={props.onDateChange || onDateChangeDefault} placeholder={props.field.label} />;
};

export default Date;
