import { EventEmitter } from 'events';

export class Emitter {
  private static instance: EventEmitter;

  private constructor() {
    // Private constructor to prevent direct instantiation
  }

  public static getInstance(): EventEmitter {
    if (!Emitter.instance) {
      Emitter.instance = new EventEmitter();
    }
    return Emitter.instance;
  }
}
