import React from 'react';
import Page from '../../../components/page/Page';
import { PageInterface as PageType } from '../../../types';

import demoPage from './demoPage';
import unionPage from './unionPage.json';

const Public = () => {
    const [page, setPage] = React.useState<PageType | null>(null);

    React.useEffect(() => {
        if (window.location.pathname === '/public/demo') {
            const page = demoPage as PageType;
            setPage(page);
        } else if (window.location.pathname === '/public/union/client') {
            const page: PageType = {
                _url: '/public/union',
                _icon: 'Circle',
                _widgets: [
                    {
                        _type: 'clientView',
                        layout: 'blankLayout',
                    },
                ],
            };

            setPage(page as PageType);
        } else if (window.location.pathname === '/public/union') {
            // Hard code the page for now, will be served from the server later
            const page = unionPage as PageType;

            setPage(page as PageType);
        }
    }, []);

    const view = React.useMemo(() => page && <Page layout={'defaultLayout'} key="123" page={page} />, [Boolean(page)]);

    return <>{view}</>;
};

export default Public;
