import { CFormTextarea } from '@coreui/react-pro';
import { getSource } from '../../../utils';
import React, { useState } from 'react';

const TextArea = (props) => {
    const [source, setSource] = useState({});
    React.useEffect(() => {
      getSource(props.source).then(value => setSource(value));
    }, []);

    return <CFormTextarea {...props} rows={4} placeholder={props.placeholder} value={source} />;
};

export default TextArea;
