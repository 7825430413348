import {
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CCardImage,
    CCardText,
    CCardTitle,
    CCollapse,
    CPlaceholder,
    CSmartTable,
} from '@coreui/react-pro';
import { Divider, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setLoading, updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import Loader from '../loader/Loader';
import { WidgetProps } from '../page/Page';
import BaseTableHeader from './Header';
import TableModal from './Modal';
import { deletePermissionIndex, FieldInterface, readPermissionIndex, writePermissionIndex } from '../input/types';
import webSocket from '../../services/websocket/webSocket';
import Icon from '../mui-icon/Icon';
import { getSource } from '../../utils';
import { Mode, permissionCheck, Query } from '../../types';
import { Emitter } from '../../services/emitter/Emitter';
import { getFile } from '../../utils/getters/getFile';
import { Column } from '@coreui/react-pro/src/components/smart-table/types';

const tableProps = {
    className: '',
    responsive: true,
    striped: true,
    hover: true,
};

const csmartTableBaseProps = {
    id: 'base-table',
    activePage: 1,
    hover: true,
    cleaner: true,
    clickableRows: true,
    columnFilter: true,
    columnSorter: {
        resetable: true,
        external: true,
    },
    footer: false,
    itemsPerPageSelect: true,
    itemsPerPage: 5,
    pagination: true,
    selectable: true,
    tableFilter: true,
    tableProps: {
        className: '',
        responsive: true,
        striped: true,
        hover: true,
    },
    tableBodyProps: {
        className: 'align-middle',
    },
};

const BaseTable = ({ widget }: WidgetProps) => {
    const widgetId = widget._id;
    const [activePage, setActivePage] = useState(1);
    const [details, setDetails] = useState({});
    const [columnFilter, setColumnFilter] = useState([]);
    const [columnSorter, setColumnSorter] = useState(null);
    let itemsOnPage = Number(localStorage.getItem(`${widgetId}.metadata.itemsPerPage`));
    if (!itemsOnPage || itemsOnPage < 5) {
        itemsOnPage = 5;
    }
    const [itemsPerPage, setItemsPerPage] = useState(itemsOnPage);
    const [total, setTotal] = useState(0);
    const [selectionValue, setSelectionValue] = useState({});
    const [loading, setLoading] = useState(false);
    
    const [columns, setColumns] = useState([] as Column[]);
    const [scopedColumns, setScopedColumns] = useState({});
    const rawColumns = widget._content ?? [];
    const [items, setItems] = useState([]);
    const [files, setFiles] = useState({});
    
    const dispatch = useAppDispatch();
    const path = widget._path?._id;
    const { sendMessage } = webSocket();
    const { t } = useTranslation();
    
    useEffect(() => {
        // Initiate the global storage for the table based on it's id
        const storagePaths = [`${widgetId}.sortedRows`, `${widgetId}.modal`, `${widgetId}.loading`];
        storagePaths.forEach(path => dispatch(updateRuntimeData(path, {})));
        
        dispatch(updateRuntimeData(`${widgetId}.rows`, []));
        dispatch(updateRuntimeData(`${widgetId}.fields`, widget?._content ?? []));
        dispatch(updateRuntimeData(`${widgetId}.mode`, Mode.VIEW)); // default table mode is viewing
        dispatch(updateRuntimeData(`${widgetId}.path`, widget._path));
        Emitter.getInstance().removeAllListeners(`${widget._path?._id}.status`);
        Emitter.getInstance().on(`${widget._path?._id}.status`, () => dataRetriever());
    }, []);
    
    useEffect(() => {
        const columns = rawColumns
        .filter(column => !column.permission || String(column.permission)[readPermissionIndex] === '1')
        .map(column => {
            return {
                key: column._key,
                label: t(column.label),
            } as Column;
        });
        columns.push({
            key: 'operations',
            label: '',
            filter: false,
            sorter: false,
            _style: { width: '115px' },
            _props: { scope: 'col', className: 'sticky-right' },
        });
        setColumns(columns);
        rawColumns?.filter(column => column.source)
        .forEach(column => {
            getSource(column.source).then(val => {
                if (val && !selectionValue.hasOwnProperty(column._key)) {
                    setSelectionValue({ ...selectionValue, [column._key]: val });
                }
            })
            .catch(error => {
                console.error(error);
            });
        });
        const rawScopedColumns = rawColumns.reduce((acc, column: FieldInterface) => {
            switch (column.type) {
                case 'selection':
                    acc[column._key] = (row) => {
                        const val = selectionValue[column._key] ? selectionValue[column._key].filter(value => value._id === row[column._key])[0] : '';
                        if (val) {
                            return (
                                <td style={cellStyle}>
                                    <CBadge style={{ alignSelf: 'center', justifySelf: 'center' }}
                                            color={val._id === '1' ? 'primary' : 'success'}>
                                        {t(val.label)}
                                    </CBadge>
                                </td>
                            );
                        } else {
                            return (<td style={cellStyle}></td>);
                        }
                    };
                    break;
                case 'email':
                    acc[column._key] = (row) => (
                        <td style={cellStyle}>
                            <a style={{ padding: '0.5rem' }} href={`mailto:${row[column._key]}`}>
                                {row[column._key]}
                            </a>
                        </td>
                    );
                    break;
                case 'file':
                    acc[column._key] = (row) => (
                        <td style={cellStyle}>
                            {
                                row[column._key] && (
                                    <Icon name={fileImageByType(column.subtype)}
                                          onClick={() => toggleDetails({
                                              _id: row._id,
                                              subtype: column.subtype,
                                              files: row[column._key],
                                          })}
                                          style={{ color: 'white' }} />)
                            }
                        </td>
                    );
                    break;
            }
            return acc;
        }, {});
        rawScopedColumns['operations'] = (item) => {
            return (
                <td style={{
                    backgroundColor: 'var(--cui-card-bg)',
                    position: 'sticky',
                    right: '-0px',
                    top: '0px',
                    alignItems: 'center',
                }}>
                    {permissionCheck(item.permission, writePermissionIndex) ?
                        <IconButton
                            style={{ backgroundColor: 'rgba(0,0,0,0.15)', marginLeft: '5px', marginRight: '5px' }}
                            disableRipple
                            disableTouchRipple
                            onClick={() => {
                                dispatch(updateRuntimeData(`${widgetId}.mode`, Mode.EDIT));
                                Object.keys(item).forEach(key => {
                                    if (columns.some(column => column.key === key)) {
                                        dispatch(updateRuntimeData(`${widgetId}.${key}.value`, item[key]));
                                    }
                                });
                            }}
                        >
                            <Icon name="EditOutlined" style={{ color: 'white' }} />
                        </IconButton>
                        : <></>
                    }
                    {permissionCheck(item.permission, deletePermissionIndex) ?
                        <IconButton
                            style={{ backgroundColor: 'rgba(0,0,0,0.15)', marginLeft: '5px', marginRight: '5px' }}
                            disableRipple
                            disableTouchRipple
                            onClick={() => {
                                if (window.confirm(t('Are you sure you want to delete?'))) {
                                    sendMessage(`${path}`, 'DELETE', { _id: item._id })?.then(val => {
                                        console.info('val' + val);
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                                }
                            }}
                        >
                            <Icon name="DeleteTwoTone" style={{ color: 'orangered' }} />
                        </IconButton>
                        : <></>
                    }
                </td>
            );
        };
        rawScopedColumns['details'] = (item) => {
            return (
                details[item._id] ? <CCollapse visible={details[item._id].show} className="row gap-2">
                        {details[item._id].show && filesRetriever(details[item._id].files)}
                    </CCollapse>
                    : ''
            );
        };
        setScopedColumns(rawScopedColumns);
    }, [rawColumns, details, files]);
    
    const toggleDetails = (event: never) => {
        let data = details[event._id];
        if (data === undefined) {
            data = event;
        }
        data['show'] = !data['show'];
        setDetails({ ...details, [data._id]: data });
    };
    
    useEffect(() => {
        Object.keys(selectionValue).forEach(key => {
            if (rawColumns.filter(column => column._key === key)[0].type === 'selection') {
                scopedColumns[key] = (row) => {
                    const val = selectionValue[key].filter(value => value._id === row[key])[0];
                    if (val) {
                        return (
                            <td style={cellStyle}>
                                <CBadge style={{ alignSelf: 'center', justifySelf: 'center' }}
                                        color={val._id === '1' ? 'primary' : 'success'}>
                                    {t(val.label)}
                                </CBadge>
                            </td>
                        );
                    } else {
                        return (<td style={cellStyle}></td>);
                    }
                };
            }
        });
    }, [selectionValue]);
    
    const cellStyle = {
        padding: '0.5rem',
        boxShadow: 'inset 0 0 0 9999px var(--cui-table-bg-state, var(--cui-table-bg-type, var(--cui-table-accent-bg)))',
        minHeight: '61px',
    };
    
    const fileImageByType = (type: any) => {
        switch (type) {
            case 'image':
                return 'Image';
            case 'video':
                return 'Videocam';
            case 'document':
                return 'Article';
            default:
                return 'InsertDriveFile';
        }
    };
    
    const fillMissingColumns = (rows: Record<string, unknown>[], columns: Column[]) => {
        return rows.map(row => {
            const newRow = { ...row };
            columns.forEach(column => {
                if (newRow[column.key] === undefined || newRow[column.key] === null) {
                    newRow[column.key] = '';
                }
            });
            return newRow;
        });
    };
    
    function filesRetriever(filesId) {
        if (!filesId || filesId.length === 0) {
            return (<></>);
        }
        if (Array.isArray(filesId)) {
            const result = [];
            filesId.forEach((id) => {
                const file: File = files[id];
                if (file) {
                    const fileUrl = URL.createObjectURL(file);
                    const card = (<CCard style={{ width: '18rem' }} className="col-4">
                        <CCardImage orientation="top" src={fileUrl} />
                        <CCardBody>
                            <CCardText>{file.name}</CCardText>
                        </CCardBody>
                    </CCard>);
                    result.push(card);
                } else {
                    const card = (<CCard style={{ width: '18rem' }} className="col-4">
                        <CCardImage as="svg" orientation="top" width="100%" height="162"
                                    xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder"
                                    preserveAspectRatio="xMidYMid slice" focusable="false">
                            <title>Placeholder</title>
                            <rect width="100%" height="100%" fill="#868e96"></rect>
                        </CCardImage>
                        <CCardBody>
                            <CPlaceholder as={CCardText} animation="glow">
                                <CPlaceholder xs={7} />
                            </CPlaceholder>
                        </CCardBody>
                    </CCard>);
                    result.push(card);
                    getFile(id).then(resultFile => {
                        if (!resultFile) {
                            return;
                        }
                        setFiles({...files, [id]: resultFile });
                    })
                    .catch(error => {
                        console.error('There was a problem retrieving file!', error);
                    });
                }
            });
            return result;
        }
    }
    
    function dataRetriever() {
        localStorage.setItem(`${widgetId}.metadata.itemsPerPage`, `${itemsPerPage}`);
        setLoading(true);
        const query: Query = { size: itemsPerPage, page: activePage - 1 };
        const filters: { [key: string]: any } = {};
        Object.keys(columnFilter).forEach((key) => {
            if (columnFilter[key] && columnFilter[key] !== '') {
                filters[key] = columnFilter[key];
            } else {
                delete filters[key];
            }
        });
        if (Object.keys(filters).length > 0) {
            query.filters = filters;
        }
        if (columnSorter && columnSorter.column !== undefined) {
            query.sort = columnSorter.column;
            query.order = columnSorter.state;
        }
        sendMessage(widget._path?._id, 'GET', query)?.then(response => {
            if (!response) {
                return;
            }
            if (response.total && response.total[0]) {
                setTotal(response.total[0].total);
            }
            let data = [];
            if (response.data) {
                data = response.data;
            }
            if (Array.isArray(response) && data.length === 0) {
                data = response;
            }
            setItems(fillMissingColumns(data, columns));
            setLoading(false);
        })
        .catch(error => {
            console.error(error);
        });
    }
    
    useEffect(() => {
        dataRetriever();
    }, [activePage, columnFilter, columnSorter, itemsPerPage]);
    
    return (
        <div style={{ overflowY: 'auto' }}>
            {loading && <Loader type="linear" />}
            <Divider className="my-4" />
            <BaseTableHeader title={t(widget.label)} widgetId={widgetId} permission={widget.permission} />
            
            <CCard className="px-4 py-2" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                <TableModal id={widgetId} />
                
                {columns.length > 0 ? (
                    <CSmartTable
                        tableProps={tableProps}
                        columnFilter={{
                            external: true,
                        }}
                        columnSorter={{
                            external: true,
                        }}
                        itemsPerPage={itemsPerPage}
                        itemsPerPageSelect
                        pagination={{
                            external: true,
                        }}
                        paginationProps={{
                            activePage: activePage,
                            pages: Math.ceil(total / itemsPerPage) || 1,
                        }}
                        columns={columns}
                        items={items}
                        noItemsLabel={t('No items')}
                        tableFilterLabel={t('Search')}
                        itemsPerPageLabel={t('Items per page')}
                        itemsNumber={total}
                        tableFilterPlaceholder={t('Type here') + '...'}
                        scopedColumns={scopedColumns}
                        onActivePageChange={(activePage) => setActivePage(activePage < 1 ? 1 : activePage)}
                        onColumnFilterChange={(filter) => {
                            setActivePage(1);
                            setColumnFilter(filter);
                        }}
                        onItemsPerPageChange={(itemsPerPage) => {
                            setActivePage(1);
                            setItemsPerPage(itemsPerPage);
                        }}
                        onSorterChange={(sorter) => setColumnSorter(sorter)}
                    />
                ) : (
                    <div>No columns defined</div>
                )}
            </CCard>
        </div>
    );
};

export default React.memo(BaseTable);
