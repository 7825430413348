import { CModal, CModalHeader } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAppSelect from '../../../services/redux/useAppSelect';
import { buttonStyle } from '../../../views/pages/union/ClientViewt';
import Icon from '../../mui-icon/Icon';

const CameraComponent = ({ onCapture, onRecordingStart, onRecordingStop }) => {
    const videoRef = React.useRef(null);
    const [mediaStream, setMediaStream] = React.useState(null);
    const [mediaRecorder, setMediaRecorder] = React.useState(null);
    const [isCameraReady, setIsCameraReady] = React.useState(false);

    React.useEffect(() => {
        // Request access to the webcam
        async function enableStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setMediaStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setIsCameraReady(true);
            } catch (err) {
                console.error('Camera access denied:', err);
            }
        }

        enableStream();

        // Cleanup on unmount
        return () => {
            mediaStream?.getTracks().forEach((track) => track.stop());
        };
    }, []);

    const capturePhoto = () => {
        if (videoRef.current && isCameraReady) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
            canvas.toBlob((blob) => {
                onCapture(blob);
            }, 'image/jpeg');
        }
    };

    const startRecording = () => {
        if (mediaStream && isCameraReady) {
            const recorder = new MediaRecorder(mediaStream);
            recorder.ondataavailable = (event) => {
                onRecordingStart(event.data);
            };
            recorder.start();
            setMediaRecorder(recorder);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            onRecordingStop();
            setMediaRecorder(null);
        }
    };

    // Inside your CameraComponent
    React.useEffect(() => {
        async function enableStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setMediaStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setIsCameraReady(true);
            } catch (err) {
                console.error('Camera access denied:', err);
            }
        }

        enableStream();
    }, []);

    return (
        <div>
            <video ref={videoRef} autoPlay playsInline muted style={{ maxWidth: '100%', maxHeight: '400px' }} />
            {/* Other buttons and elements */}
        </div>
    );
};
const iconForSubtype = (string) => {
    switch (string) {
    case 'file':
        return 'Image';
    case 'image':
        return 'AddAPhoto';
    case 'video':
        return 'PhotoCameraFront';
    default:
        return '';
    }
};
const ButtonInput = (props) => {
    const { subtype, label } = props;
    const rtl = useAppSelect('rtl');
    const [modalVisible, setModalVisible] = React.useState(false);
    const fileInputRef = React.useRef(null);
    const { t } = useTranslation();

    const handleButtonClick = () => {
        fileInputRef?.current?.click();
    };

    const handleCapture = (blob) => {
        // Handle the captured photo or video blob here
    };

    const renderInputForSubtype = () => {
        switch (subtype) {
        case 'photo':
        case 'video':
            return (
                <>
                    <CameraComponent
                        onCapture={handleCapture}
                        onRecordingStart={handleCapture}
                        onRecordingStop={(d) => {
                            console.log('recording stopped', d);
                        }}
                        isRecording={subtype === 'video'}
                    />
                </>
            );
        default:
            return null;
        }
    };

    // Function to check if the user is on a mobile device
    const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    let inputAttributes = {};
    if (isMobileDevice()) {
        // Mobile device attributes
        switch (subtype) {
        case 'photo':
            inputAttributes = { accept: 'image/*', capture: 'environment' };
            break;
        case 'video':
            inputAttributes = { accept: 'video/*', capture: 'camcorder' };
            break;
        case 'file':
            inputAttributes = { accept: '*/*' };
            break;
            // ...
        }
    } else {
        // Desktop attributes
        switch (subtype) {
        case 'photo':
        case 'video':
        case 'file':
            inputAttributes = { accept: '*/*' }; // or any other appropriate setting for desktop
            break;
            // ...
        }
    }

    const handleFileChange = (event) => {
        console.log('file changed', event.target.files);
    };

    const baseStyle = {
        minWidth: '200px',
        padding: '14px 50px',
        paddingRight: rtl ? '14px' : '50px',
        paddingLeft: rtl ? '50px' : '14px',
        marginBottom: '24px',
        background: 'hotpink',
        fontSize: '32px',
        color: 'white',
        borderRadius: '8px',
    };
    const style = {
        ...baseStyle,
        ...props.style,
    };

    return (
        <>
            <input type="file" ref={fileInputRef} {...inputAttributes} style={{ display: 'none' }} onChange={handleFileChange} />

            <button style={{ ...style, buttonStyle }} onClick={handleButtonClick}>
                <Icon name={iconForSubtype(props.icon)} />
                <span className="mx-4" style={{ color: 'white' }}>
                    {t(label)}
                </span>
            </button>

            {subtype !== 'file' && (
                <CModal alignment="center" visible={false} onClose={() => setModalVisible(false)}>
                    <CModalHeader>{label}</CModalHeader>
                    {renderInputForSubtype()}
                </CModal>
            )}
        </>
    );
};

export default ButtonInput;
