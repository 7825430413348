import { CHeader } from '@coreui/react-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../../components/form';
import webSocket from '../../../services/websocket/webSocket';
import { FieldInterface } from '../../../components/input/types';
import DefaultLayout from '../../layout/DefaultLayout';

const UserProfile = () => {
    const [userFields, setUserFields] = React.useState<FieldInterface[]>([]);
    const [userMetadata, setUserMetadata] = React.useState<FieldInterface[] | null>(null);
    const { sendMessage } = webSocket();
    const { t } = useTranslation();

    const onSubmit = (data) => {
        console.log('submit', data);

        // Save the new user to the server
    };

    return (
        <DefaultLayout>
            <CHeader>{t('User Profile')}</CHeader>
            <Form content={userMetadata ?? userFields} row={JSON.parse(localStorage.getItem('user') ?? '{}')} onSubmit={onSubmit} />
        </DefaultLayout>
    );
};

export default UserProfile;
