import { cilCloudUpload } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCloseButton } from '@coreui/react-pro';
import { Stack } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSource } from '../../../utils';
import Icon from '../../mui-icon/Icon';
import StyleWrapper from '../../style-wrapper';
import Label from '../Label';
import { FieldComponentProps } from '../Field';
import useSendMessage from '../../../services/websocket/webSocket';
import { Buffer } from 'buffer';
import { staticRoutes } from '../../../configuration/constants';
import { getFile } from '../../../utils/getters/getFile';

const fileTypes = {
    image: 'png, jpg, gif, bmp, svg, ico, jpeg',
    video: 'mp4, mov, avi, wmv, vob',
    document: 'pdf, doc, txt, xls, ppt, odp',
    audio: 'mp3, m4a, wav',
};

// Adjusted styles for better alignment
const fileInputStyle = {
    display: 'none',
};

const labelStyle = {
    marginTop: '0',
    backgroundColor: 'var(--cui-table-bg)',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
    minHeight: '35px',
    padding: '0.75rem',
};

const selectedFileNameStyle = {
    marginTop: '-8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '50px',
};

const CHUNK_SIZE = 1024 * 256; // 256kb chunks

const File = (props: PropsWithChildren<FieldComponentProps>) => {
    // const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { t } = useTranslation();
    const { sendMessage } = useSendMessage();
    const [source, setSource] = useState({});
    const [files, setFiles] = useState([]);
    const [filesId, setFilesId] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState({});
    const { field } = props;
    
    React.useEffect(() => {
        if (field.source) {
            getSource(field.source).then(value => setSource(value));
        }
        if (field.value && Array.isArray(field.value)) {
            field.value.forEach(val => {
                getFile(val)
                .then(file => {
                    if (!file) {
                        return;
                    }
                    setUploadingFiles((prev) => ({
                        ...prev,
                        [file.name]: {
                            ...prev[file.name],
                            id: val,
                            progress: 100,
                            status: 'done',
                        },
                    }));
                    const updatedFiles: File[] = files;
                    if (!updatedFiles.some(f => f.name === file.name)) {
                        updatedFiles.push(file);
                        setFilesId([...filesId, val]);
                        setFiles(updatedFiles);
                    }
                })
                .catch(error => {
                    console.error('There was a problem to get file id: %d error: %d', val, error);
                });
            });
        }
    }, []);
    
    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = Array.from(e.target.files);
        if (field.amount !== undefined && field.amount > 1 && selectedFiles.length > field.amount) {
            return;
        }
        const newFiles = selectedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            progress: 0,
            status: 'pending',
        }));
        
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        selectedFiles.forEach(file => {
            if (!file) {
                errorHandler(file, 'file is missing');
                return;
            }
            const expectedFileType = field.subtype ?? 'image';
            const expectedFileSubtype = fileTypes[expectedFileType];
            
            const foundFile = file && file.type?.split('/');
            if (!foundFile) {
                errorHandler(file, 'Unexpected file type: ' + file.type);
                return;
            }
            
            const filePrefix = foundFile[0];
            const fileSuffix = foundFile[1];
            
            // Make sure the file type matches the expected type
            // Check if the file's MIME type matches the expected file type
            if (filePrefix !== expectedFileType && !fileSuffix?.includes(expectedFileSubtype)) {
                errorHandler(file, 'Unexpected file type: ' + file.type);
                return;
            }
            if (!file.size) {
                errorHandler(file, 'file size is missing');
                return;
            }
            uploadFile(file);
        });
    };
    
    function errorHandler(file, error) {
        setUploadingFiles((prev) => ({
            ...prev,
            [file.name]: {
                ...prev[file.name],
                status: 'error',
            },
        }));
        console.error('Upload failed:', error);
    }
    
    const uploadFile = async (file: File) => {
        if (!file) {
            return;
        }
        setUploadingFiles((prev) => ({
            ...prev,
            [file.name]: { ...prev[file.name], progress: 0, status: 'uploading' },
        }));
        const parts = splitFile(file);
        for (let i = 0; i < parts.length; i++) {
            if (uploadingFiles[i] && uploadingFiles[i].status === 'error') {
                return;
            }
            const part = parts[i];
            const reader = new FileReader();
            await new Promise<void>((resolve) => {
                reader.onerror = (error) => {
                    console.error('Error reading file:', error);
                    errorHandler(file, error);
                    resolve();
                };
                reader.onload = (e: ProgressEvent<FileReader>) => {
                    const chunkData = e.target?.result as ArrayBuffer;
                    const message = {
                        name: file.name,
                        part: i + 1,
                        total: parts.length,
                        type: file.type,
                        data: Array.from(Buffer.from(chunkData).values()),
                    };
                    console.info('sending part: ' + message.part + ' length: ' + message.data.length);
                    sendMessage(staticRoutes.files, 'CREATE', message).then(response => {
                        if (!response) {
                            return;
                        }
                        if (response._id) {
                            setUploadingFiles((prev) => ({
                                ...prev,
                                [file.name]: {
                                    ...prev[file.name],
                                    id: response._id,
                                    progress: 100,
                                    status: 'done',
                                },
                            }));
                            if (props.onChange) {
                                const ids = [...filesId, response._id];
                                setFilesId(ids);
                                props.onChange(ids);
                            }
                        } else {
                            setUploadingFiles((prev) => ({
                                ...prev,
                                [file.name]: {
                                    ...prev[file.name],
                                    progress: (response / parts.length) * 100,
                                    status: 'uploading',
                                },
                            }));
                        }
                        resolve();
                    })
                    .catch(error => {
                        errorHandler(file, error);
                        resolve();
                    });
                };
                reader.readAsArrayBuffer(part);
            });
        }
    };
    
    function splitFile(file: File): Blob[] {
        const parts: Blob[] = [];
        let start = 0;
        
        while (start < file.size) {
            const end = Math.min(start + CHUNK_SIZE, file.size);
            parts.push(file.slice(start, end));
            start = end;
        }
        
        return parts;
    }
    
    const getColor = (progress) => {
        if (!progress) return 'red';
        if (progress < 50) return 'orange';
        if (progress < 75) return 'yellow';
        if (progress === 100) return 'green';
        return '';
    };
    
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    
    const clickInput = React.useCallback(() => {
        fileInputRef.current?.click();
    }, []);
    
    return (
        <Stack sx={{ background: 'transparent' }} direction="column" spacing={2} marginTop="auto" marginBottom="auto">
            <input ref={fileInputRef} {...(field.subtype && { accept: fileTypes[field.subtype] })}
                   type="file"
                   id={field._key}
                   multiple={field.amount !== undefined ? field.amount > 1 || field.amount === 0 : false}
                   onChange={handleFileChange} style={fileInputStyle} />
            {field.label && (
                <StyleWrapper display="flex" alignItems="center" justifyContent="space-between" marginTop="0">
                    <Label required={field.required}>{t(field.label)}</Label>
                    <div>
                        <label htmlFor={field._key} style={labelStyle}>
                            <CIcon size="sm" icon={cilCloudUpload}></CIcon>
                        </label>
                    </div>
                </StyleWrapper>
            )}
            <Stack direction="row-reverse" spacing={0}
                   style={{ marginTop: 'auto', marginBottom: 'auto', background: 'transparent' }}
                   justifyContent="space-between" flexDirection="row">
                {field.icon && (
                    <label onClick={clickInput} htmlFor={field._key} style={{ cursor: 'pointer' }}>
                        <Icon color="black" name={source ?? 'QrCodeScannerOutlined'} />
                    </label>
                )}
                {files.map((file) => (
                    file &&
                    <StyleWrapper key={file.id ?? file.name} display="flex" alignItems="center" gap="0.5rem"
                                  marginTop="auto" marginBottom="10px"
                                  borderRadius="0.375rem"
                                  border="var(--cui-border-width) solid var(--cui-input-border-color, var(--cui-border-color))"
                                  padding="5px">
                        <CCloseButton
                            style={{ scale: 0.7 }}
                            onClick={() => {
                                const handler = () => {
                                    setUploadingFiles(Object.assign({}, Object.values(uploadingFiles).filter(item => item.name !== file.name)));
                                    setFiles(l => l.filter(item => item.name !== file.name));
                                    if (props.onChange) {
                                        props.onChange(file._id);
                                    }
                                };
                                let fileId;
                                Object.keys(uploadingFiles).forEach((key) => {
                                    if (key === file.name) {
                                        fileId = uploadingFiles[key].id;
                                    }
                                });
                                let data;
                                if (fileId) {
                                    data = { _id: fileId };
                                    const ids = filesId;
                                    if (ids) {
                                        setFilesId(ids.filter(item => item !== fileId));
                                    }
                                } else {
                                    data = { name: file.name };
                                }
                                sendMessage(staticRoutes.files, 'DELETE', data)
                                .then(() => handler())
                                .catch(error => {
                                    console.error(error);
                                });
                            }}
                        />
                        <div style={{
                            height: '100%',
                            width: `${uploadingFiles[file.name]?.progress || 0}%`,
                            color: `${getColor(uploadingFiles[file.name]?.progress || 0)}`,
                            transition: 'width 0.3s',
                        }}>{file.name}</div>
                    </StyleWrapper>
                ))}
            </Stack>
        </Stack>
    );
};

export default File;
