import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { capitalize } from '@mui/material';
import _ from 'lodash';
import { PropsWithChildren } from 'react';
import { dismountForm, updateRuntimeData } from '../../services/redux/globalStateManager';
import useAppDispatch from '../../services/redux/useAppDispatch';
import useAppSelect from '../../services/redux/useAppSelect';
import { FieldInterface } from '../input/types';
import { Mode, WidgetInterface } from '../../types';
import { singular } from '../../utils';
import { Widget } from '../WidgetRenderer';

interface TableModalProps {
    // Props Here
    id: string;
}

const TableModal = (props: PropsWithChildren<TableModalProps>) => {
    const { id } = props; // Will be used to target the table's current data from storage

    const runtime = useAppSelect('runtime');

    const { mode, fields, modal } = runtime[id] ?? {};
    const edit = {};
    if (runtime[id]) {
      Object.keys(runtime[id])?.filter(item => fields?.some(field => field._key === item)).forEach((item) => {edit[item] = runtime[id][item].value});
    }

    const dispatch = useAppDispatch();

    const formWidgetId = id;

    const handleClose = () => {
        dispatch(updateRuntimeData(`${id}.mode`, Mode.VIEW));
        fields?.forEach(field => dispatch(updateRuntimeData(`${id}.${field._key}`, {})));
    };

    const fieldsWithDefaultValues = fields?.map((col: FieldInterface) => ({ ...col, ...(edit ? { value: edit[col._key] } : {}) }));

    const formWidget: WidgetInterface = {
        _id: formWidgetId,
        _content: fieldsWithDefaultValues,
        _type: 'form',
    };

    const submitWidget: WidgetInterface = {
        _id: id,
        _content: fieldsWithDefaultValues,
        _type: 'submit',
        _subtype: mode,
        label: 'Submit',
        _path: _.get(runtime, `${id}.path`),
    };

    return (
        <CModal size="xl" onClose={handleClose} visible={mode === Mode.CREATE || mode === Mode.EDIT}>
            <CModalHeader>
                <CModalTitle>{singular(capitalize(modal?.title ?? ''))} </CModalTitle>
            </CModalHeader>

            <CModalBody>
                <Widget widget={formWidget} />
                <Widget widget={submitWidget} />
            </CModalBody>
        </CModal>
    );
};
export default TableModal;
