import {
    CImage,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand,
    CSidebarHeader,
    CSidebarNav,
    CSidebarToggler,
} from '@coreui/react-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { staticRoutes } from '../configuration/constants';
import { setPages, setSidebarHover, setSidebarShow, setSidebarUnfoldable } from '../services/redux/globalStateManager';
import useAppDispatch from '../services/redux/useAppDispatch';
import useAppSelect from '../services/redux/useAppSelect';
import { AppSidebarNav } from './AppSidebarNav';
import RtlAdjustHorizontal from './RtlAdjustHorizontal';
import { TextReveal } from './foldable/Text';
import webSocket from '../services/websocket/webSocket';
import { PageInterface } from '../types';
import { cilSpeedometer } from '@coreui/icons';
import { getLabel, getRoute } from '../utils';
import CIcon from '@coreui/icons-react';
import Icon from './mui-icon/Icon';

const AppSidebar = () => {
    const dispatch = useAppDispatch();
    const routes = useAppSelect('routes');
    const { sendMessage } = webSocket();
    const [pages, setPages] = useState<PageInterface[]>();
    const unfoldable = useAppSelect('sidebarUnfoldable');
    const sidebarShow = useAppSelect('sidebarShow');
    const { t } = useTranslation();
    const isRtl = useAppSelect('rtl');
    const navigate = useNavigate();
    
    // React.useEffect(() => {
    //     const body = document.querySelector('div.body');
    //     if (body) {
    //         if (!unfoldable) {
    //             // Remove wide and add narrow
    //             body.className = body.className.replace('adjust-to-sidebar-narrow', '');
    //             body.className = body.className = body.className + ' adjust-to-sidebar-wide';
    //         } else {
    //             // Remove narrow and add wide
    //             body.className = body.className.replace('adjust-to-sidebar-wide', '');
    //             body.className = body.className = body.className + ' adjust-to-sidebar-narrow';
    //         }
    //     }
    // }, [unfoldable]);
    
    useEffect(() => {
        if (routes?._metadata_pages) {
            sendMessage(routes._metadata_pages, 'GET')
            ?.then(response => {
                if (response) {
                    setPages((response.data ?? (Array.isArray(response) ? response : [])).sort((a, b) => a.order - b.order));
                }
            })
            ?.catch(error => {
                console.error(error);
            });
        }
    }, [routes]);
    
    return (
        <CSidebar
            className="border-end"
                  position="fixed"
                  placement={isRtl ? 'end' : 'start'}
                  visible={sidebarShow}
                  unfoldable={unfoldable}
                  onMouseEnter={() => {
                      dispatch(setSidebarHover(true));
                  }}
                  onMouseLeave={() => {
                      dispatch(setSidebarHover(false));
                  }}
        >
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand className="d-none d-md-flex" onClick={() => navigate(staticRoutes.dashboard)}>
                    <CImage
                        src="/betec.png"
                        alt="Betec Dashboard"
                        className="d-inline-block align-top"
                        width={45}
                    />
                    <TextReveal>{t('Betec Dashboard')}</TextReveal>
                </CSidebarBrand>
            </CSidebarHeader>
            <CSidebarNav>
                {Array.isArray(pages) && pages.map((item) => {
                    const url = getRoute(item);
                    
                    if (!url) {
                        return null;
                    }
                    return (
                        <CNavItem key={item._id} href={url}>
                            {item?.icon && <Icon sx={{ height: '30px', width: '30px', marginRight: '0.4rem', marginLeft: '0.4rem'}} name={item.icon} />}
                            {t(getLabel(item).toLowerCase()) ?? ''}
                        </CNavItem>
                    );
                })}
            </CSidebarNav>
            <CSidebarHeader className="border-top">
                <CSidebarToggler className="d-lg-flex"
                                 onClick={() => {
                                     dispatch(setSidebarUnfoldable(!unfoldable));
                                 }} />
            </CSidebarHeader>
        </CSidebar>
        // <RtlAdjustHorizontal>
        //     <CSidebar
        //         position="fixed"
        //         unfoldable={unfoldable}
        //         placement={isRtl ? 'end' : 'start'}
        //         visible={sidebarShow}
        //         onMouseEnter={() => {
        //             dispatch(setSidebarHover(true));
        //         }}
        //         onMouseLeave={() => {
        //             dispatch(setSidebarHover(false));
        //         }}
        //         onVisibleChange={visible => {
        //             dispatch(setSidebarShow(visible));
        //         }}
        //     >
        //         <CSidebarBrand
        //             className="d-none d-md-flex"
        //             style={{
        //                 justifyContent: 'flex-start',
        //                 paddingLeft: '0.75rem',
        //                 paddingRight: '0.7rem',
        //                 cursor: 'pointer',
        //                 marginBottom: '4.25rem',
        //                 display: 'flex',
        //                 alignItems: 'center',
        //             }}
        //             onClick={() => navigate(staticRoutes.dashboard)}
        //         >
        //             <img
        //                 alt="Betec Dashboard"
        //                 src="/betec.png"
        //                 width={45}
        //                 style={{
        //                     marginTop: '0.75rem',
        //                     marginRight: '-0.125rem',
        //                     marginLeft: '-0.125rem',
        //                 }}
        //             />
        //             <div style={{ marginTop: '0.25rem' }}>
        //                 <TextReveal>{t('Betec Dashboard')}</TextReveal>
        //             </div>
        //         </CSidebarBrand>
        //         <CSidebarNav>
        //             <SimpleBar>
        //                 <AppSidebarNav />
        //             </SimpleBar>
        //         </CSidebarNav>
        //         <CSidebarToggler
        //             className="d-lg-flex"
        //             onClick={() => {
        //                 dispatch(setSidebarUnfoldable(!unfoldable));
        //             }}
        //         />
        //     </CSidebar>
        // </RtlAdjustHorizontal>
    );
};

export default React.memo(AppSidebar);
